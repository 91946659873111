import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Router from "./Router";
import {Toaster} from "react-hot-toast";

ReactDOM.render(
    <React.StrictMode>
        <Toaster/>
        <Router/>
    </React.StrictMode>,
    document.getElementById("root")
);
