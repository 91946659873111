/* eslint-disable react/prop-types */
import React, {useState} from "react";
import {Col, Form, Modal, Row} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import categoriesApi from "../../../api/categoriesApi";

export default function UpdateBrandFilterModal({show, brands, onHide, category, callback}) {
    const [selectedBrand, setSelectedBrand] = useState(null)
    const [name, setName] = useState(null)

    const changeBrand = (e) => setSelectedBrand(e.target.value)
    const changeName = (e) => setName(e.target.value)

    const checkRequiredFields = () => (selectedBrand && name);

    const handleSave = () => {
        if (!checkRequiredFields()) {
            return false;
        }
        categoriesApi.addCategoryBrand({
            brandUuid: selectedBrand,
            categoryUuid: category.uuid,
            name: name
        }).then(callback)
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title
                    id="contained-modal-title-vcenter">Filtre {category ? "-" : ""} {category?.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group as={Row}>
                    <Form.Label column={true} sm="2">Marque</Form.Label>
                    <Col sm="10">
                        <select multiple={false} value={selectedBrand} onChange={changeBrand}>
                            <option value=''>--- Aucun ---</option>
                            {(brands ?? []).map(c => (<option key={c.uuid} value={c.uuid}>{c.name}</option>))}
                        </select>
                    </Col>
                    <Form.Label column={true} sm="2">Nom</Form.Label>
                    <Col sm="10">
                        <Form.Control type="text" placeholder="Nom du filtre" value={name}
                                      onChange={changeName}/>
                    </Col>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onHide}>Annuler</Button>
                <Button disabled={!checkRequiredFields()} variant="dark" onClick={() => handleSave()}>
                    {category?.slug ? "Sauvegarder" : "Créer"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
