import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {useParams} from "react-router-dom";
import {Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import categoriesApi from "api/categoriesApi";
import LogoutBar from "../../../globalCompo/LogoutBar";
import CategoryMenu from "../CategoryMenu";
import UpdateBrandFilterModal from "./UpdateBrandFilterModal";
import DeleteIcon from "@material-ui/icons/Delete";

export default function Brands() {
    const classes = useStyle();
    const {uuid} = useParams();
    const [category, setCategory] = useState([]);
    const [brands, setBrands] = useState([]);
    const [categoryBrand, setCategoryBrand] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const onHide = () => setShowModal(false);

    const addFilters = () => setShowModal(true);

    const getCategory = () => categoriesApi.get(uuid).then(res => setCategory(res.data))

    const getBrands = () => categoriesApi.getBrands(uuid).then(res => setBrands(res.data))

    const getCategoryBrands = () => categoriesApi.categoryBrands(uuid).then(res => setCategoryBrand(res.data))

    const deleteCategoryBrand = (brandUuid, categoryUuid) => categoriesApi.deleteCategoryBrand({
        brandUuid: brandUuid,
        categoryUuid: categoryUuid
    }).then(() => getCategoryBrands())

    useEffect(async () => {
        getCategory()
        getBrands()
        getCategoryBrands()
    }, [uuid]);

    return (
        <>
            <LogoutBar/>
            <div className={classes.container}>
                <CategoryMenu category={category} current="brands"/>
                <div className={classes.flexSpace}>
                    <Button className={classes.button} variant="dark" onClick={addFilters}>
                        Ajouter un filtre de marque
                    </Button>
                </div>
                <Table striped bordered hover variant="dark">
                    <thead>
                    <tr>
                        <th style={{width: "70%"}}>💄 Marque</th>
                        <th style={{width: "20%"}}>Nom personalisé</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categoryBrand?.sort((a, b) => {
                        if (!a.order && !b.order) {
                            return a.name > b.name ? 1 : -1
                        }
                        return !a.order || a.order > b.order ? 1 : -1
                    }).map(categoryBrand => (
                        <tr key={categoryBrand.uuid} className={classes.row}>
                            <td>{categoryBrand.name}</td>
                            <td>{categoryBrand.custom_name}</td>
                            <OverlayTrigger placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2">Supprimer le critère</Tooltip>}>
                                {({ref, ...triggerHandler}) => (
                                    <DeleteIcon {...triggerHandler} ref={ref}
                                                onClick={() => deleteCategoryBrand(categoryBrand.uuid, category.uuid)}/>
                                )}
                            </OverlayTrigger>
                        </tr>
                    ))}

                    </tbody>
                </Table>
                <UpdateBrandFilterModal
                    show={showModal}
                    onHide={onHide}
                    brands={brands}
                    category={category}
                    callback={getCategoryBrands}
                />
            </div>
        </>
    );
}

const useStyle = makeStyles(() => ({
    container: {
        margin: "72px 48px",
        boxSizing: "border-box",
    },
    flexSpace: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: 32,
        marginBottom: 16,
    },
    button: {
        padding: "8px 12px",
        height: "3rem",
        boxSizing: "border-box",
    },
    row: {
        cursor: "pointer",
    },
}));
