/* eslint-disable react/prop-types */
import React from "react";
import {makeStyles} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {Breadcrumb, Button} from "react-bootstrap";

export default function CategoryMenu ({category, current}) {
  const classes = useStyle();
  const history = useHistory();

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push("/categories")}>Catégories</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push(`/categories/${category.uuid}`)} active={current === undefined}>{category.name}</Breadcrumb.Item>
        {current === "landings" && (<Breadcrumb.Item active>Landings</Breadcrumb.Item>)}
        {current === "features" && (<Breadcrumb.Item active>Caractéristiques</Breadcrumb.Item>)}
        {current === "brands" && (<Breadcrumb.Item active>Marques</Breadcrumb.Item>)}
      </Breadcrumb>
      <div className={classes.flexSpace}>
        <h2 className={classes.title}>{category.name}</h2>
      </div>
      <div className={classes.menu}>
        <Button className={classes.button} variant={current === undefined ? "dark" : "light"} onClick={() => history.push(`/categories/${category.uuid}`)}>
          🏷️ Critères
        </Button>
        <Button className={classes.button} variant={current === "landings" ? "dark" : "light"}
                onClick={() => history.push(`/categories/${category.uuid}/landings`)}>
          🛬 Landings
        </Button>
        <Button className={classes.button} variant={current === "features" ? "dark" : "light"}
                onClick={() => history.push(`/categories/${category.uuid}/features`)}>
          📜 Caractéristiques
        </Button>
        <Button className={classes.button} variant={current === "brands" ? "dark" : "light"}
                onClick={() => history.push(`/categories/${category.uuid}/brands`)}>
          💄 Marques
        </Button>
      </div>
    </>
  );
}

const useStyle = makeStyles(() => ({
  button: {
    padding: "8px 12px",
    height: "3rem",
    boxSizing: "border-box",
    borderRadius: ".25rem .25rem 0 0",
  },
  menu: {
    display: "flex",
    flexDirection: "row",
    gap: ".25rem",
    margin: "1rem 0",
    borderBottom: ".25rem solid #343a40",
  }
}));
